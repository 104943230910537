// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SET_CURRENT = 'set_current';

//Auth const

export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const EXPIRES_SIGNATURE = 'expires_signature';

//Company const
export const BUSINESS_INFORMATION = 'business_information';
export const CONTACT_PERSONS = 'contact_persons';
export const BUSINESS_IDENTITY = 'business_identity';
export const RESET_COMPANY = 'reset_company';
export const DEFAULT_COMPANY = 'default_company';

//Application and System Settings const
export const SYSTEM_SETTINGS = 'SYSTEM_SETTINGS';

//user
export const SET_APP_AUTHORIZATION = 'SET_APP_AUTHORIZATION';







