//base url

export const LOGIN_PAGE = 'https://pems.phillipsoutsourcing.net/login';
export const MAIN_MENU = 'https://pems.phillipsoutsourcing.net/home';
export const API_BASE_URL = 'https://api.phillipsoutsourcing.net';
export const POLAT_URL = 'https://polats.net';
export const DOCUMENT_BASE_URL = 'https://s3.us-east-2.amazonaws.com/api.phillipsoutsourcing.net';
export const POLATS_DOCUMENT_URL = 'https://s3.us-east-1.amazonaws.com/polats.net';

//Clients
export const CLIENT_URL = '/clients';
export const COMPANIES_URL = '/companies';
export const SEARCH_COMPANIES = '/search-companies';
export const CLIENTS_STATISTICS = '/clients-statistics';
export const GET_CLIENT_OPENINGS = '/get-client-openings';
export const GET_REPRESENTATIVE_CLIENTS = '/representative-clients';
export const SEARCH_EMPLOYEE_LISTS = '/search-employee-list';

//Jobs and Vacancies
export const JOB_URL = '/jobs';
export const JOB_EXPORT_GROUPS = '/job-export-groups';
export const PROFILE_EXPORT_REQUEST = '/profile-export-request';
export const JOB_APPLICANTS_URL = '/job-applicants';
export const JOB_DESCRIPTION_URL = '/job-descriptions';
export const GET_DESCRIPTION_VARIABLES = '/get-description-variables';
export const SEARCH_JOB_DESCRIPTIONS = '/search-job-descriptions';
export const GET_JOB_FUNCTIONS = '/get-job-functions';
export const GET_JOB_LOCATIONS = '/get-job-locations';
export const GET_JOB_TASKS = '/get-job-tasks';
export const JOB_FUNCTIONS = '/job-functions';
export const JOB_LOCATIONS = '/job-locations';
export const JOB_TASKS = '/job-tasks';
export const JOB_STEPS = '/job-steps';
export const JOB_APPLICATIONS = '/job-applications';
export const JOB_USERS = '/job-users';
export const SEARCH_JOB_USERS = '/search-job-users';

export const SEARCH_APPLICATIONS = '/search-applications';
export const SEARCH_JOB_APPLICANTS = '/search-job-applicants';
export const SEARCH_JOB_TITLES = '/search-job-titles';
export const GET_JOB_STATS = '/get-job-statistics';
export const APPLICANT_PROFILE = '/get-applicant-profile';
export const APPLICANT_ACCREDITATION = '/accredit-applicants';
export const REMOVE_CANDIDATE_PROFILE = '/remove-candidates-profile';
export const APPLICATION_STAGES = '/application-stages';


//Countries
export const COUNTRIES_URL = '/countries'

//others


export const MAKE_USER_COMPANY_REPRESENTATIVE = '/make-representative';
export const DEFAULT_COMPANY_URL = '/default-companies';
export const DASHBOARD_STATISTICS = '/dashboard-statistics';

export const SEARCH_COMPANY_USERS = '/search-company-users';


//users
export const SEARCH_USERS = '/search-users';

export const GET_USER_AUTHORIZATION = '/get-user-authorization';

//CANDIDATE

export const CANDIDATE_URL = '/candidates';
export const GENERAL_SEARCH_CANDIDATES = '/general-search-candidates';

//EMPLOYEE

export const EMPLOYEE_URL = '/employees';

//DOWNLOAD_DOCUMENT_WITH_TOKEN

export const DOWNLOAD_DOCUMENT_WITH_TOKEN = '/download-document'

export const GRP = '/grp-report'

export const EXPORT_GRP_APPLICANTS = '/export-grp-candidates'

export const GET_DOCUMENTS='/get-all-job-document'

export const NOTIFY_APPLICANT_EMAIL='/notify-applicants-documents'




