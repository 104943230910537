import React, {useEffect, useState} from "react";


import App from "../../routes/index";


import {useHistory, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import {Badge, Layout, Menu} from "antd";

import Sidebar from "../Sidebar";

import {BellFilled, CaretDownFilled} from "@ant-design/icons";
import {COMPANIES_URL, DOCUMENT_BASE_URL} from "../../constants/ServerUrl";


const {Content,  Header, } = Layout;
const MainApp = () => {


    const match = useRouteMatch();
    const {defaultCompany} = useSelector(({company}) => company);


    return (
        <Layout className="">
            <Sidebar />
            <Layout>
                <Header className="bg-white p-0 h-20 border-b-2 border-opacity-50">
                    {/*<div className="px-3 py-5 text-2xl cursor-pointer " onClick={() => {*/}
                    {/*    setSidebarCollapsed(!sidebarCollapsed);*/}
                    {/*}}>*/}

                    {/*    {sidebarCollapsed ? (<MenuUnfoldOutlined/>) : (<MenuFoldOutlined/>)}*/}

                    {/*</div>*/}

                    {defaultCompany && (
                        <div className="flex  justify-end space-x-20 mt-2 mr-24">
                            <div>

                                <Badge dot>
                                    <BellFilled/>
                                </Badge>
                            </div>
                            {defaultCompany.company_logo_path && (

                                <div className="flex cursor-pointer ">
                                    <div className=" rounded-full  p-1 ">

                                        <img width="50" className="rounded-full" alt={defaultCompany.business_name}
                                             src={`${DOCUMENT_BASE_URL}/${defaultCompany.company_logo_path}`}/>
                                    </div>
                                    <div>

                                        <p className=" pl-3 font-semibold">{defaultCompany.business_name}
                                            <CaretDownFilled
                                                className=" pl-4"/></p>
                                    </div>
                                </div>
                            )}
                            {!defaultCompany.company_logo_path && (


                                <div className="flex cursor-pointer ">
                                    <div className=" rounded-full  p-1 ">

                                        <img width="50" className="rounded-full" alt="POSNL"
                                             src="/images/app-icons/placeholder.jpg"/>
                                    </div>
                                    <div>

                                        <p className=" pl-3 font-semibold">{defaultCompany.business_name}
                                            <CaretDownFilled
                                                className=" pl-4"/></p>
                                    </div>
                                </div>


                            )}


                        </div>

                    )}


                </Header>
                <Content className="bg-gray-50 pt-10 px-10 overflow-auto h-screen">

                    <App match={match}/>
                    {/*<Footer>*/}
                    {/*    <div className=" gx-layout-footer-content">*/}
                    {/*        {footerText}*/}
                    {/*    </div>*/}
                    {/*</Footer>*/}
                </Content>

            </Layout>
        </Layout>
    )
};
export default MainApp;

