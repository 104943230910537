import React, {useEffect} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import UserProfile from "./UserProfile";

import {useSelector} from "react-redux";
import {HomeFilled} from "@ant-design/icons";
import {FaCity, FaWrench, FaDatabase, FaPen, FaBook, FaObjectGroup} from "react-icons/fa";
import Helpers from "../../util/Helpers";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {

    let {navStyle, themeType} = useSelector(({settings}) => settings);
    let {pathname} = useSelector(({common}) => common);
    const {authorizations} = useSelector(({auth}) => auth);

    let selectedKeys = pathname.substr(1);
    let defaultOpenKeys = selectedKeys.split('/')[1];
    // const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
        <>

            <div className=" w-full">

                <div className="flex items-center justify-center py-2 px-2">
                    <UserProfile/>
                    {/*<AppsNavigation/>*/}
                </div>
            </div>
            <Menu
                className="text-center text-base font-medium my-5 text-gray-500"
                mode="inline"
                theme="light"
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}>

                <MenuItemGroup key="dashboard" className="mb-5 ">

                    <Menu.Item key="home">
                        <Link to="/home" className="flex">
                            <div className="text-2xl">
                                <HomeFilled/>
                            </div>
                            <span className="mx-4 py-1">Home</span>
                        </Link>
                    </Menu.Item>

                </MenuItemGroup>

                <MenuItemGroup key="accounting" className="font-semibold"
                               title={<div className="px-3 text-left  ">RECRUITMENT</div>}>


                    <SubMenu key="company"
                             title={<div className="flex my-3  ">
                                 <div className="text-lg">

                                     <FaCity/>
                                 </div>
                                 <div className="px-4 -my-3">Company</div>
                             </div>}>


                        <Menu.Item key="company/clients">
                            <Link to="/company/clients">
                                <span className="pl-4  text-sm">Clients</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/jobs">
                            <Link to="/company/jobs">

                                <span className="pl-4  text-sm">Jobs & Vacancies</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/candidates">
                            <Link to="/company/candidates">

                                <span className="pl-4  text-sm">Candidates</span>
                            </Link>
                        </Menu.Item>
                        {/*<Menu.Item key="company/employees">*/}
                        {/*    <Link to="/company/employees">*/}
                        {/*        <span className="pl-4  text-sm">Employees</span>*/}
                        {/*    </Link>*/}
                        {/*</Menu.Item>*/}


                    </SubMenu>
                    <SubMenu key="applications"
                             title={<div className="flex my-3  ">
                                 <div className="text-lg">
                                     <FaBook/>
                                 </div>
                                 <div className="px-4 -my-3">Applications</div>
                             </div>}>

                        <Menu.Item key="applications/registered-users">
                            <Link to="/applications/registered-users">

                                <span className="pl-4  text-sm">Registered Users</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="applications/application-center">
                            <Link to="/applications/application-center">

                                <span className="pl-4  text-sm">Application Center</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="applications/progress-timeline">
                            <Link to="/applications/progress-timeline">

                                <span className="pl-4  text-sm">Progress Timeline</span>
                            </Link>
                        </Menu.Item>

                    </SubMenu>
                    <SubMenu key="grp"
                             title={<div className="flex my-3 ">
                                 <div className="text-lg">
                                     <FaObjectGroup/>
                                 </div>
                                 <div className="px-4 -my-3">Grp Center</div>
                             </div>}>
                             <Menu.Item key="grp/reports">
                                <Link to="/grp/reports">

                                    <span className="pl-4  text-sm">Report</span>
                                </Link>
                            </Menu.Item>

                    </SubMenu>
                    <SubMenu key="assessments"
                             title={<div className="flex my-3 ">
                                 <div className="text-lg">
                                     <FaPen/>
                                 </div>
                                 <div className="px-4 -my-3">Assessments</div>
                             </div>}>

                        <Menu.Item key="assessments/assessment-center">
                            <Link to="/assessments/assessment-center">

                                <span className="pl-4  text-sm">Assessment Center</span>
                            </Link>
                        </Menu.Item>

                       


                        <Menu.Item key="applications/applicants">
                            <Link to="/applications/applicants">

                                <span className="pl-4  text-sm">Assessment Setup</span>
                            </Link>
                        </Menu.Item>

                    </SubMenu>
                    <SubMenu key="reports"
                             title={<div className="flex my-3">
                                 <div className="text-lg">

                                     <FaDatabase/>
                                 </div>
                                 <div className="px-4 -my-3">Reports</div>
                             </div>}>


                        <Menu.Item key="reports/applicant-reports">
                            <Link to="/reports/applicant-reports">

                                <span className="pl-4  text-sm">Applicant Reports</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="reports/global-reports">
                            <Link to="/reports/global-reports">

                                <span className="pl-4  text-sm">Global Reports</span>
                            </Link>
                        </Menu.Item>

                    </SubMenu>
                    <SubMenu key="setup"
                             title={<div className="flex my-3  ">
                                 <div className="text-lg">

                                     <FaWrench/>
                                 </div>
                                 <div className="px-4 -my-3">General Setup</div>
                             </div>}>


                        <Menu.Item key="setup/application-tasks">
                            <Link to="/setup/application-tasks">

                                <span className="pl-4  text-sm">Application Tasks</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="setup/job-descriptions">
                            <Link to="/setup/job-descriptions">

                                <span className="pl-4  text-sm">Job Descriptions</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="setup/job-functions">
                            <Link to="/setup/job-functions">

                                <span className="pl-4  text-sm">Job Functions</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="setup/job-locations">
                            <Link to="/setup/job-locations">

                                <span className="pl-4  text-sm">Job Locations</span>
                            </Link>
                        </Menu.Item>


                    </SubMenu>

                </MenuItemGroup>

            </Menu>

        </>
    );
};

SidebarContent.propTypes = {};
export default SidebarContent;

