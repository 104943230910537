import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Button, Dropdown, Menu, Popover} from "antd";
import {setCurrent, userSignOut} from "../../appRedux/actions";
import {DownOutlined, ShopFilled, PoweroffOutlined} from "@ant-design/icons";
import {FaUser} from "react-icons/fa";
import {Link} from "react-router-dom";
import Helpers from "../../util/Helpers";

const UserProfile = () => {
    const dispatch = useDispatch();
    const {authUser,authorizations} = useSelector(({auth}) => auth);


    const menu = (
        <Menu className="pl-1" >

            <Menu.Item key="1">
                <div className="cursor-pointer" onClick={()=>{
                    dispatch(userSignOut());
                }}>
                    <PoweroffOutlined  className="text-lg"/>

                    <span className="pl-10 align-middle font-semibold">Sign out</span>
                </div>

            </Menu.Item>
        </Menu>
    );

    return (


        <Dropdown trigger="click" overlay={menu} className="border-0">
            <Button type="text"
                className="focus:bg-gray-100 focus:bg-opacity-70 rounded w-full py-auto h-11 flex bg-opacity-70  hover:bg-gray-100"
                size="large">
                <div className="rounded-full bg-gray-500 text-white  p-1 text-xl">
                    <FaUser/>

                </div>
                <span
                    className="mx-auto  font-semibold">{authUser ? authUser.first_name.toUpperCase() : ''}</span>
                <div className="align-middle text-xs "><DownOutlined/></div>
            </Button>
        </Dropdown>


    )
};

export default UserProfile;
