import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "../util/asyncComponent";

const App = ({match}) => (
    <div className="">

        <Switch>
            <Route path={`${match.url}/`} component={asyncComponent(() => import('./Home'))}/>
            <Route path={`${match.url}home`} component={asyncComponent(() => import('./Home'))}/>
            <Route path={`${match.url}company/clients`} component={asyncComponent(() => import('./Clients'))}/>
            <Route path={`${match.url}company/jobs`} component={asyncComponent(() => import('./Jobs'))}/>
            <Route path={`${match.url}company/job-applicants/:slug`}
                   component={asyncComponent(() => import('./Jobs/JobApplicants'))}/>

            <Route path={`${match.url}company/candidates`} component={asyncComponent(() => import('./Candidates'))}/>
            {/*<Route path={`${match.url}company/employees`} component={asyncComponent(() => import('./Employees'))}/>*/}

            <Route path={`${match.url}setup/job-descriptions`}
                   component={asyncComponent(() => import('./JobDescriptions'))}/>
            <Route path={`${match.url}setup/job-locations`}
                   component={asyncComponent(() => import('./JobLocations'))}/>
            <Route path={`${match.url}setup/job-functions`}
                   component={asyncComponent(() => import('./JobFunctions'))}/>
            <Route path={`${match.url}setup/job-description/new`}
                   component={asyncComponent(() => import('./JobDescriptions/NewJobDescription'))}/>
            <Route path={`${match.url}setup/job-description/edit/:slug`}
                   component={asyncComponent(() => import('./JobDescriptions/UpdateJobDescription'))}/>

            <Route path={`${match.url}applications/application-center`}
                   component={asyncComponent(() => import('./ApplicationCenter'))}/>
            <Route path={`${match.url}applications/registered-users`}
                   component={asyncComponent(() => import('./RegisteredUsers'))}/>
            <Route path={`${match.url}applications/progress-timeline`}
                   component={asyncComponent(() => import('./ProgressTimeline'))}/>
            <Route path={`${match.url}setup/application-tasks`}
                   component={asyncComponent(() => import('./ApplicationTasks'))}/>
              <Route path={`${match.url}grp/reports`} component={asyncComponent(() => import('./Grp'))}/>
            <Route path={`${match.url}download-resume/:token`}
                   component={asyncComponent(() => import('./DownloadResume'))}/>

        </Switch>
    </div>
);

export default App;
